import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import AllServicesAWS from '../../../containers/Services/AWS/AWS-All-Services';

const propTypes = {
  data: PropTypes.shape({
    contentfulAwsSections: PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.shape({
        json: PropTypes.shape({
          content: PropTypes.array,
          nodeType: PropTypes.string,
        }),
      }),
      icon: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }).isRequired,
      footerIcons: PropTypes.array.isRequired,
      footerTitle: PropTypes.string.isRequired,
      listItems: PropTypes.array.isRequired,
      listTitle: PropTypes.string.isRequired,
      networkDiagram: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }).isRequired,
    }),
    companyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

const ServicesPage = ({ data }) => {
  return (
    <>
      <AllServicesAWS
        title={data.contentfulAwsSections.title}
        slug={data.contentfulAwsSections.slug}
        description={data.contentfulAwsSections.description}
        footerIcons={data.contentfulAwsSections.footerIcons}
        footerTitle={data.contentfulAwsSections.footerTitle}
        listItems={data.contentfulAwsSections.listItems}
        listTitle={data.contentfulAwsSections.listTitle}
        networkDiagram={data.contentfulAwsSections.networkDiagram}
        icon={data.contentfulAwsSections.icon}
        companyQuote={data.companyQuote}
      />
    </>
  );
};

ServicesPage.propTypes = propTypes;
export default ServicesPage;

export const query = graphql`
  query MyQuery($slug: String) {
    contentfulAwsSections(slug: { eq: $slug }) {
      slug
      description {
        json
      }
      title
      listTitle
      listItems {
        listTitle
        listDescription {
          listDescription
        }
      }
      icon {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      footerTitle
      footerIcons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      networkDiagram {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    companyQuote: contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;
