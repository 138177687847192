import { Link } from 'gatsby';
import Img from 'gatsby-image';
import MarkdownIt from 'markdown-it';
import PropTypes from 'prop-types';
import React from 'react';

import { getSeoConfig } from '../../../../constants/defaultSeoProps';
import Button from '../../../../components/Button';
import CompanyQuote from '../../../../components/CompanyQuote';
import Layout from '../../../../components/Layout';
import renderContent from '../../../../utils/rich-text-helpers';
import s from './AWS-Services.module.scss';

const parse = require('html-react-parser');

const md = new MarkdownIt({
  html: true,
  linkify: false,
});

const propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.array,
      nodeType: PropTypes.string,
    }),
  }).isRequired,
  icon: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
  }).isRequired,
  footerIcons: PropTypes.array.isRequired,
  footerTitle: PropTypes.string.isRequired,
  listItems: PropTypes.array.isRequired,
  listTitle: PropTypes.string.isRequired,
  networkDiagram: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
  }).isRequired,
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.array.isRequired,
  }).isRequired,
};

const AllServicesAWS = ({
  title,
  slug,
  description,
  icon,
  footerIcons,
  footerTitle,
  listItems,
  listTitle,
  networkDiagram,
  companyQuote,
}) => {
  const serviceInfo = {
    'lambda-service': {
      imageUrl: '/og-images/aws-lambda-service.png',
      title: "Maximize Application Efficiency with Tech Holding's AWS Lambda Consulting Services",
      description:
        'Streamline AWS Lambda deployment, monitoring, security, & performance with Tech Holding. Expert solutions for scalable, cost-effective serverless applications.',
    },
    'database-service': {
      imageUrl: '/og-images/aws-database-service.png',
      title: 'Expert AWS RDS Solutions by Tech Holding: Customized, Scalable, Cost-Efficient',
      description:
        'Customized AWS RDS services by Tech Holding: Expertise, scalability, cost-efficiency, and 24/7 support for seamless integration and management.',
    },
    'api-gateway': {
      imageUrl: '/og-images/aws-api-gateway.png',
      title: "Optimize Connectivity with Tech Holding's AWS API Gateway Solutions",
      description:
        'Tech Holding offers expert AWS API Gateway services for seamless connectivity & robust API management. Leverage certified expertise & custom strategies.',
    },
    'opensearch-service': {
      imageUrl: '/og-images/aws-opensearch-service.png',
      title: "Optimize Data Insights with Tech Holding's AWS OpenSearch Services",
      description:
        "Streamline data search, analytics & decision-making with Tech Holding's expert AWS OpenSearch services. Benefit from deep expertise & robust solutions",
    },
    'data-and-analytics-service': {
      imageUrl: '/og-images/aws-data-and-analytics-service.png',
      title: "Elevate Business Intelligence: Tech Holding's AWS Data & Analytics Solutions",
      description:
        'Unlock the power of AWS Redshift, Glue, Kinesis, & OpenSearch. Maximize data insights, streamline processes, and drive business growth with Tech Holding',
    },
    'migration-service': {
      imageUrl: '/og-images/aws-migration-services.png',
      title: "We simplify your journey to the cloud with Tech Holding's AWS Migration Services",
      description:
        "Leverage Tech Holding's AWS Migration Services for a seamless transition to the cloud, enhancing efficiency, reducing costs, and preparing your infrastructure for the future.",
    },
    'ecs-eks-service': {
      imageUrl: '/og-images/ecsEksService.png',
      title:
        'Our expertise in containerization transforms the way businesses deploy, manage, and scale applications.',
      description:
        'Leverage the strengths of Amazon Elastic Container Service (ECS) and Amazon Elastic Kubernetes Service (EKS) to deliver robust, scalable, and efficient solutions tailored to meet the unique demands of your operations.',
    },
  };

  // Function to get the image URL and description based on the AWS Service title
  const getServiceInfo = (awsServiceTitle) => {
    return serviceInfo[awsServiceTitle];
  };

  const awsService = getServiceInfo(slug);

  const seoParams = {
    ogImagePath: awsService.imageUrl,
    ogTitle: awsService.title,
    ogDesc: awsService.description,
    ogPath: `/services/aws/${slug}`,
  };

  const updatedSeo = getSeoConfig(seoParams);

  const parseToHtml = (content) => parse(md.render(content));

  return (
    <Layout
      currentPage="/services/aws/{aws-service}"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <div className={s.pageContainer} data-test="pageContainer">
        {/* Title Header */}
        <div className={s.titleContainer}>
          <div data-test="title">
            <h2>{title}</h2>
            <div>
              {description?.json?.content.map((content) => renderContent(content, s.paragraph))}
            </div>
            <div className={s.button}>
              <Link to="/work">
                <Button theme="grey">See Our Work</Button>
              </Link>
            </div>
          </div>
          {icon?.fluid && (
            <div key={icon?.fluid?.src} data-test="icon">
              <Img className={s.image} fluid={icon?.fluid} />
            </div>
          )}
        </div>

        {/* Network Diagram */}
        {networkDiagram && (
          <div data-test="networkDiagram">
            {networkDiagram?.fluid && (
              <Img fluid={networkDiagram?.fluid} data-test="networkDiagram" />
            )}
          </div>
        )}

        {/* List Items */}
        {listItems && (
          <div className={s.listItemsContainer}>
            <h3 className={s.listTitle} data-test="listTitle">
              {listTitle}
            </h3>
            <div className={s.itemContainer}>
              {listItems?.map((item) => (
                <div data-test="listItems" key={item.listTitle}>
                  <h4 className={s.title}>{item.listTitle}</h4>
                  <p>{parseToHtml(item.listDescription?.listDescription)}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Footer Banner */}
      {footerTitle && (
        <div className={s.container}>
          <div className={s.footerContainer}>
            <div className={s.title} data-test="footerTitle">
              <h2>{footerTitle}</h2>
            </div>
            <div className={footerIcons.length > 1 ? s.multiLogoGrid : s.singleLogoContainer}>
              {footerIcons?.map((logo) => (
                <div className={s.logoIcon} key={logo?.fluid?.src} data-test="footerIcons">
                  <Img className={s.emoji} fluid={logo?.fluid} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <CompanyQuote companyQuote={companyQuote} />
    </Layout>
  );
};

AllServicesAWS.propTypes = propTypes;
export default AllServicesAWS;
